import '../../node_modules/@glidejs/glide/dist/css/glide.core.min.css';
import '../styles/index.scss';
import ovaticIcon from "-!svg-inline-loader?classPrefix!../assets/img/ovatic-icon.svg";
import ticketIcon from "-!svg-inline-loader?classPrefix!../assets/img/ticket-icon.svg";
import planningIcon from "-!svg-inline-loader?classPrefix!../assets/img/planning-icon.svg";
import marketingIcon from "-!svg-inline-loader?classPrefix!../assets/img/marketing-icon.svg";
import codeIcon from "-!svg-inline-loader?classPrefix!../assets/img/code-icon.svg";
import dashboardIcon from "-!svg-inline-loader?classPrefix!../assets/img/dashboard-icon.svg";
import nextButtonIcon from "-!svg-inline-loader?classPrefix!../assets/img/white-right-round-arrow.svg";
import prevButtonIcon from "-!svg-inline-loader?classPrefix!../assets/img/white-left-round-arrow.svg";
import checkIcon from "-!svg-inline-loader?classPrefix!../assets/img/check-icon.svg";
import plusIcon from "-!svg-inline-loader?classPrefix!../assets/img/plus-icon.svg";

import theaterIcon from "-!svg-inline-loader?classPrefix!../assets/img/icons/schouwburg.svg";
import sportIcon from "-!svg-inline-loader?classPrefix!../assets/img/icons/sport.svg";
import museaIcon from "-!svg-inline-loader?classPrefix!../assets/img/icons/musea.svg";
import recreatieIcon from "-!svg-inline-loader?classPrefix!../assets/img/icons/recreatie.svg";
import filmIcon from "-!svg-inline-loader?classPrefix!../assets/img/icons/film.svg";
import accomodatieverhuurIcon from "-!svg-inline-loader?classPrefix!../assets/img/icons/accomodatieverhuur.svg";

import ovaticLogo from "-!svg-inline-loader?classPrefix!../assets/img/ovatic-logo.svg";
import rightArrow from "-!svg-inline-loader?classPrefix!../assets/img/right-arrow.svg";
import roundRightArrow from "-!svg-inline-loader?classPrefix!../assets/img/round-right-arrow.svg";
import indexHeaderBlob from "-!svg-inline-loader?classPrefix!../assets/img/index-swirl.svg";
import upArrow from "-!svg-inline-loader?classPrefix!../assets/img/arrow-up.svg";
import { Autoplay } from '@glidejs/glide/dist/glide.modular.esm';
import Glide from '@glidejs/glide';
const scrollToElement = require('scroll-to-element');

var glide = {};

document.addEventListener("DOMContentLoaded", function(event) {
	loadSvgs();
	loadEvents();
	initGlide();
	initTestimonialsGlide();
});

function initGlide(){
	if(document.getElementsByClassName("glide")[0]){
		glide = new Glide('.glide', {
			type: "carousel",
			breakpoints: {
				600: {
					perView: 2,
					gap: 50,
					autoplay: 2000,	
				}
			},
			perView: 3,
			gap: 10,
			autoplay: 2000,	
		});
		glide.mount({Autoplay});

		// Glide Home navigation
		let galleryPrevButton = document.getElementById("home-gallery-prev");
		if(galleryPrevButton){
			galleryPrevButton.addEventListener('click', function(){ glide.go("<"); });
		}

		let galleryNextButton = document.getElementById("home-gallery-next");
		if(galleryNextButton){
			galleryNextButton.addEventListener('click', function(){ glide.go(">"); });
		}
	}
}

function initTestimonialsGlide(){
	if(document.getElementsByClassName("big-glide")[0]){
		glide = new Glide('.big-glide', {
			type: "carousel",
			breakpoints: {
				600: {
					perView: 1,
					gap: 50
				}
			},
			perView: 1,
			gap: 10,
			autoplay: 4000,		
		});
		glide.mount({Autoplay});

		// Glide Home navigation
		let galleryPrevButton = document.getElementById("big-gallery-prev");
		if(galleryPrevButton){
			galleryPrevButton.addEventListener('click', function(){ glide.go("<"); });
		}

		let galleryNextButton = document.getElementById("big-gallery-next");
		if(galleryNextButton){
			galleryNextButton.addEventListener('click', function(){ glide.go(">"); });
		}
	}
}

function loadEvents(){
	// Hamburger
	let hamburger = document.getElementsByClassName("hamburger")[0];
	if(hamburger){
		let mobileNav = document.getElementById("navigation-mobile");
		let body = document.body;
		hamburger.addEventListener("click", function(){
			if(this.classList.contains("hamburger-open")){
				this.classList.remove("hamburger-open");
				mobileNav.classList.remove("navigation-mobile-open");
				body.classList.remove("disabled-scrolling");
			}else{
				this.classList.add("hamburger-open");
				mobileNav.classList.add("navigation-mobile-open");
				body.classList.add("disabled-scrolling");
			}
		});
	}

	let mobileSubOpener = document.getElementById("nav-item-sub-opener");
	if(mobileSubOpener){
		let mobileSubMenu = document.getElementById("mobile-sub-menu");
		mobileSubOpener.addEventListener("click", function(e){

			let arrow = {};
			let svgList = ["svg", "g", "span"];
			if(svgList.includes(e.target.parentElement.tagName.toLowerCase())){
				arrow = e.path.find((elem) => {
					return elem.tagName.toLowerCase() === "span";
				});
			}else{
				arrow = e.target.parentElement.querySelector(".mobile-nav-arrow");
			}

			if(mobileSubMenu.classList.contains("nav-item-sub-open")){
				mobileSubMenu.classList.remove("nav-item-sub-open");
				arrow.classList.remove("mobile-nav-arrow-open");
			}else{
				mobileSubMenu.classList.add("nav-item-sub-open");
				arrow.classList.add("mobile-nav-arrow-open");
			}
		});
	}

	let mobileSubOpener1 = document.getElementById("nav-item-sub-opener1");
	if(mobileSubOpener1){
		let mobileSubMenu = document.getElementById("mobile-sub-menu1");
		mobileSubOpener1.addEventListener("click", function(e){
			let arrow = {};
			let svgList = ["svg", "g", "span"];
			if(svgList.includes(e.target.parentElement.tagName.toLowerCase())){
				arrow = e.path.find((elem) => {
					return elem.tagName.toLowerCase() === "span";
				});
			}else{
				arrow = e.target.parentElement.querySelector(".mobile-nav-arrow");
			}
			if(mobileSubMenu.classList.contains("nav-item-sub-open")){
				mobileSubMenu.classList.remove("nav-item-sub-open");
				arrow.classList.remove("mobile-nav-arrow-open");
			}else{
				mobileSubMenu.classList.add("nav-item-sub-open");
				arrow.classList.add("mobile-nav-arrow-open");
			}
		});
	}

	// Scrollable effects
	let scrollableElements = document.getElementsByClassName("scrollable-enter");
	window.addEventListener('scroll', function (event) {
		elementInView(scrollableElements);
	}, false);

	// Scroll up button
	let headerElement = document.getElementById("header");
	let scrollUpButton = document.getElementById("scroll-up-button");
	scrollUpButton.addEventListener("click", function(e){
		scrollToElement(headerElement);
	});

	window.onscroll = (e) => {
		if(window.pageYOffset >= 100){
			scrollUpButton.classList.add("scroll-up-button-show");
		}else{
			scrollUpButton.classList.remove("scroll-up-button-show");
		}
	};

	elementInView(scrollableElements);
}

function elementInView(elements){
	let bounding = {};
	elements.forEach(elem => {
		if(isAnyPartOfElementInViewport(elem)){
			if(!elem.classList.contains("scrollable-enter-show")){
				elem.classList.add("scrollable-enter-show");
			}
		}	
	});	
}

function isAnyPartOfElementInViewport(el) {

    const rect = el.getBoundingClientRect();
    // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

    // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
    const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
    const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

    return (vertInView && horInView);
}

function loadSvgs(){
	loadSvgById([
		{id: "home-gallery-prev", svg: prevButtonIcon},
		{id: "home-gallery-next", svg: nextButtonIcon},
		{id: "big-gallery-prev", svg: prevButtonIcon},
		{id: "big-gallery-next", svg: nextButtonIcon},
		{id: "mobile-logo", svg: ovaticLogo},
		{id: "index-header-illustration", svg: indexHeaderBlob},
		{id: "scroll-up-button", svg: upArrow}
	]);
	
	loadSvgByClass([
		{class: "anchor-arrow", svg: roundRightArrow},
		{class: "nav-icon-ovatic", svg: ovaticIcon},
		{class: "nav-icon-ticket", svg: ticketIcon},
		{class: "nav-icon-planning", svg: planningIcon},
		{class: "nav-icon-marketing", svg: marketingIcon},
		{class: "nav-icon-code", svg: codeIcon},
		{class: "nav-icon-dashboard", svg: dashboardIcon},
		{class: "check-icon", svg: checkIcon},
		{class: "plus-icon", svg: plusIcon},
		{class: "mobile-nav-arrow", svg: rightArrow},
		{class: "card-anchor-arrow", svg: roundRightArrow},
		{class: "nav-icon-theater", svg: theaterIcon},
		{class: "nav-icon-musea", svg: museaIcon},
		{class: "nav-icon-sport", svg: sportIcon},
		{class: "nav-icon-film", svg: filmIcon},
		{class: "nav-icon-accomodatieverhuur", svg: accomodatieverhuurIcon},
		{class: "nav-icon-recreatie", svg: recreatieIcon},
	]);
}

function loadSvgByClass(data){
	data.forEach(item => {
		document.getElementsByClassName(item.class).forEach(element => { element.innerHTML = item.svg; });
	});
}

function loadSvgById(data){
	data.forEach(item => {
		let elem = document.getElementById(item.id);
		if(elem){
			elem.innerHTML = item.svg;
		}
	});
}